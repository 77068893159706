import { useNavigate } from "react-router-dom";
import gg from '../Assets/gg2.jpg';
import gg2 from '../Assets/gg3.jpg';
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import Service from "../Components/Service";
import vin from '../Assets/vin.png';
import dtr from '../Assets/datart.png';
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import getUserLocale from "get-user-locale";

function Home({locale}) {
    const navigate = useNavigate();
    const [invoices, setInvoices] = useState(0);
    const [users, setUsers] = useState(0);
    const [earned, setEarned] = useState(0);
    const { t } = useTranslation();
    const questions = t('faq.questions', { returnObjects: true });

    useEffect(() => {
        fetch("https://api.whustle.com/stats")
            .then(res => res.json())
            .then((result) => {
                setInvoices(result.total_usage);
                setUsers(result.total_users);
                if(locale !== "cs") {
                    setEarned((parseFloat(result.total_earned) * 1000) / 25);
                }
            }).catch((error) => {
            console.log(error);
            console.log("Failed to fetch stats");
        });
    }, []);
    const replacePlaceholders = (str, placeholders) => {
        return str.replace(/{(\w+)}/g, (_, key) => placeholders[key] || '');
    };

    return (
        <div>
            <Navbar />
            <div className="flex flex-col items-center justify-center md:mx-8 mx-6">
                <h1 className="mt-5 text-center font-bold md:text-[35px] text-4xl bg-gradient-to-r from-red-600 via-orange-500 to-red-400 inline-block text-transparent bg-clip-text">
                    {t('main.title')}
                </h1>

                <div className="flex items-center justify-center"></div>

                <div className={"mb-5 md:mx-32 mx-8 text-center md:my-4 my-4"}>
                    <h1 className={"text-white md:text-[40px] text-3xl font-bold"}>
                        {t('main.sub-title')}
                    </h1>
                    <p className={"font-semibold md:my-4 my-4"}>
                        {t('main.headline')}
                    </p>
                </div>

                <div className={"grid md:grid-cols-2 grid-cols-1 md:space-x-2 space-x-0 md:space-y-0 space-y-4"}>
                    <div className="tooltip tooltip-bottom">
                        <div className="indicator">
                            <span className="indicator-item indicator-top indicator-center badge font-bold bg-red-600 text-white">
                                {t('main.buttons.subscribe.label')}
                            </span>
                            <a dangerouslySetInnerHTML={{ __html: t('main.buttons.subscribe.content')}} href={getUserLocale() === "cs" ? "https://buy.stripe.com/28obMH0oreSn1J64gm" : "https://buy.stripe.com/eVa3gb3ADh0v4Vi8wE"}
                               className={"btn font-black text-white bg-gradient-to-r from-blue-600 via-blue-500 to-indigo-400"}>
                            </a>
                        </div>
                    </div>
                    <button dangerouslySetInnerHTML={{ __html: t('main.buttons.free-trial.content')}} onClick={() => navigate("/user?demo=true")}
                            className={"btn font-black bg-gradient-to-r from-red-600 via-orange-500 to-red-400 text-white"}>
                    </button>
                </div>

                <h1 className={"text-[25px] font-bold my-8"}>{t('presentation.title')}</h1>
                <div className={" md:mx-0 mx-4 h-fit"}>
                    <div className="flex justify-center">
                        <img className="w-32" src={vin} />
                    </div>
                    <p className={"mt-4 text-center"} dangerouslySetInnerHTML={{ __html: t('presentation.headline') }}></p>
                </div>
                <h1 className={"text-[25px] font-bold my-8"}>{t('presentation.tools.title')}</h1>
                <div className={"md:mx-0 mx-4 h-fit"}>
                    <div className={"grid md:grid-cols-2 sm:grid-cols-1 space-x-0 md:space-x-4 space-y-4 md:space-y-0"}>
                        <Service title={t('presentation.tools.cards.0.title')}
                                 tease={<p>{t('presentation.tools.cards.0.description')}</p>}
                                 description={<div>
                                     <h3 className={"relative left-[-16px] font-bold"}>{t('presentation.tools.cards.0.sub-title')}</h3>
                                     <ul className={"list-disc"}>
                                         {t('presentation.tools.cards.0.features', { returnObjects: true }).map((feature, index) => (
                                             <li key={index}>{feature}</li>
                                         ))}
                                     </ul>
                                 </div>}
                                 img={dtr}
                                 label={t('presentation.tools.cards.0.label')}
                                 labelStyle={"bg-gradient-to-r from-red-600 via-orange-500 to-red-400"}
                                 tags={t('presentation.tools.cards.0.categories', { returnObjects: true })} />

                        <Service title={t('presentation.tools.cards.1.title')}
                                 tease={<p>{t('presentation.tools.cards.1.description')}</p>}
                                 description={<div>
                                     <h3 className={"relative left-[-16px] font-bold"}>{t('presentation.tools.cards.1.sub-title')}</h3>
                                     <ul className={"list-disc"}>
                                         {t('presentation.tools.cards.1.features', { returnObjects: true }).map((feature, index) => (
                                             <li key={index}>{feature}</li>
                                         ))}
                                     </ul>
                                 </div>}
                                 img={"https://logos-world.net/wp-content/uploads/2021/10/StockX-New-Logo.png"}
                                 tags={t('presentation.tools.cards.1.categories', { returnObjects: true })}
                                 label={t('presentation.tools.cards.1.label')}
                                 labelStyle={"bg-gradient-to-r from-red-600 via-orange-500 to-red-400"} />

                        <Service title={t('presentation.tools.cards.2.title')}
                                 tease={<p>{t('presentation.tools.cards.2.description')}</p>}
                                 description={<div>
                                     <h3 className={"relative left-[-16px] font-bold"}>{t('presentation.tools.cards.2.sub-title')}</h3>
                                     <ul className={"list-disc"}>
                                         {t('presentation.tools.cards.2.features', { returnObjects: true }).map((feature, index) => (
                                             <li key={index}>{feature}</li>
                                         ))}
                                     </ul>
                                 </div>}
                                 img={"https://media.zenfs.com/en/latestly_557/f3ca1503fe6bfa94145726ddc181fa63"}
                                 tags={t('presentation.tools.cards.2.categories', { returnObjects: true })}
                                 label={t('presentation.tools.cards.2.label')}
                                 labelStyle={"bg-gradient-to-r from-green-600 via-green-500 to-green-400"} />

                        <Service title={t('presentation.tools.cards.3.title')}
                                 tease={<p>{t('presentation.tools.cards.3.description')}</p>}
                                 description={<div>
                                     <h3 className={"relative left-[-16px] font-bold"}>{t('presentation.tools.cards.3.sub-title')}</h3>
                                     <ul className={"list-disc"}>
                                         {t('presentation.tools.cards.3.features', { returnObjects: true }).map((feature, index) => (
                                             <li key={index}>{feature}</li>
                                         ))}
                                     </ul>
                                 </div>}
                                 img={"https://c.static-nike.com/a/images/w_1920,c_limit/bzl2wmsfh7kgdkufrrjq/image.jpg"}
                                 tags={t('presentation.tools.cards.3.categories', { returnObjects: true })}
                                 label={t('presentation.tools.cards.3.label')}
                                 labelStyle={"bg-gradient-to-r from-green-600 via-green-500 to-green-400"} />
                    </div>
                </div>

                <h1 className={"text-[25px] font-bold my-4"}>{t('results.title')}</h1>
                <div className="stats shadow mb-4 w-fit flex flex-col md:grid">
                    <div className="stat place-items-center">
                        <div className="stat-title">{t('results.fields.0')}</div>
                        <div className="stat-value">{invoices}</div>
                    </div>
                    <div className="stat place-items-center">
                        <div className="stat-title">{t('results.fields.1')}</div>
                        <div className="stat-value bg-gradient-to-r from-red-600 via-orange-500 to-red-400 inline-block text-transparent bg-clip-text">
                            {earned} {locale === "cs" ? "Kč" : "€"}
                        </div>
                    </div>
                    <div className="stat place-items-center">
                        <div className="stat-title">{t('results.fields.2')}</div>
                        <div className="stat-value">{users}</div>
                    </div>
                </div>
                <div className="mx-5 grid md:grid-cols-2 sm:grid-cols-1 space-x-2 md:w-[800px] w-96 md:h-[700px] h-[1200px]">
                    <div className="mockup-browser bg-base-300 h-[75%]">
                        <div className="mockup-browser-toolbar">
                            <div className="input">https://tools.resellhub.pro</div>
                        </div>
                        <img src={gg} />
                    </div>
                    <div className="mockup-browser bg-base-300 h-[75%]">
                        <div className="mockup-browser-toolbar">
                            <div className="input">https://tools.resellhub.pro</div>
                        </div>
                        <img src={gg2} />
                    </div>
                </div>
            </div>
            <div className={"mb-8 items-center justify-center flex"}>
                <iframe className={"bg-[#141414]"}
                        src="https://discord.com/widget?id=1150451137233813706&theme=dark"
                        width="350" height="500"
                        allowTransparency="true" frameBorder="0"
                        sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"></iframe>
            </div>
            <div className="mb-8 items-center justify-center flex flex-col md:w-1/3 w-fit md:mx-auto mx-4 space-y-4">
                <h1 className={"text-3xl font-bold mb-4"}>{t('faq.title')}</h1>

                {Object.keys(questions).map((key) => {
                    const content = t(`faq.questions.${key}.content`);
                    const replacedContent = replacePlaceholders(content, { users, amount: earned });

                    return (
                        <div key={key} className="collapse collapse-arrow bg-base-200">
                            <input type="checkbox" />
                            <div className="collapse-title text-xl font-medium">
                                {t(`faq.questions.${key}.title`)}
                            </div>
                            <div className="collapse-content">
                                <span dangerouslySetInnerHTML={{ __html: replacedContent }}></span>
                            </div>
                        </div>
                    );
                })}
            </div>

            <Footer />
        </div>
    );
}

export default Home;
