import Navbar from "../../Components/Navbar";
import Footer from "../../Components/Footer";
import {useTranslation} from "react-i18next";

function ResellShopy() {
    const {t} = useTranslation();

    return (
        <div>
            <Navbar/>
            <div className={"md:w-1/2 w-full mx-auto"}>
                <div className="hero">
                    <div className="hero-content flex-col lg:flex-row">
                        <img
                            src="https://resellerheaven.eu/cdn/shop/files/Navrh_bez_nazvu_3_1.png?v=1724925806&width=180"
                            className="w-92 rounded-lg shadow-2xl"/>
                        <div>
                            <h1 className="text-5xl font-bold">ResellerHeaven</h1>
                            <p className="py-6">
                                {t("stores.0.description")}
                            </p>
                            <a href="https://resellerheaven.eu" target={"_blank"} className="btn">  {t("stores.0.button-text")}</a>
                        </div>
                    </div>
                </div>

                <div className="hero">
                    <div className="hero-content flex-col lg:flex-row">
                        <img
                            src="https://vendorsworld.de/cdn/shop/files/Vendors_uzsi.png?v=1724679266&width=135"
                            className="w-92 rounded-lg shadow-2xl mr-10"/>
                        <div>
                            <h1 className="text-5xl font-bold">VendorsWorld</h1>
                            <p className="py-6">
                                {t("stores.1.description")}
                            </p>
                            <a href="https://vendorsworld.de" target={"_blank"} className="btn"> {t("stores.1.button-text")}</a>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default ResellShopy;