import {Routes, Route, BrowserRouter} from "react-router-dom";
import Home from "./Pages/Home";
import StockXSell from "./Pages/Services/StockXSell";
import Datart from "./Pages/Services/Datart";
import StockXBuy from "./Pages/Services/StockXBuy";
import Login from "./Pages/User/Login";
import User from "./Pages/User/User";
import ResellShopy from "./Pages/Subs/ResellShopy";
import Faktury from "./Pages/Faktury";
import Funkce from "./Pages/Funkce";
import Payment from "./Pages/User/Payment";
import Register from "./Pages/User/Register";
import React, {useEffect, useState} from "react";
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Paid from "./Pages/User/Paid";
import Ucet from "./Pages/User/Ucet";
import RefundPolicy from "./Pages/Subs/Refund";
import TermsOfSerice from "./Pages/Subs/TOS";
import TermsOfService from "./Pages/Subs/TOS";
import AlzaSK from "./Pages/Services/AlzaSK";
import Rey from "./Pages/Services/Rey";
import Checkout from "./Pages/User/Test";
import {Elements} from "@stripe/react-stripe-js";
import {loadStripe} from "@stripe/stripe-js";
import getUserLocale from 'get-user-locale';

import i18n from "i18next";
import {useTranslation, initReactI18next} from "react-i18next";

import czech from "./Assets/cs.json";
import slovak from "./Assets/sk.json";
import english from "./Assets/en.json";
import german from "./Assets/de.json";

i18n
    .use(initReactI18next)
    .init({
        debug: true,
        resources: {
            cs: {
                translation: czech
            },
            sk: {
                translation: slovak
            },
            en: {
                translation: english
            },
            de: {
                translation: german
            }
        },
        lng: getUserLocale(),
        fallbackLng: "en",
        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

function App() {
    const [clientSecret, setClientSecret] = useState(null);
    const [userLocale, setUserLocale] = useState(null);
    const stripePromise = loadStripe("pk_live_51Ozc6fFqQPKh8TUqUUqKnd5DnZdakxxj67nxBO8ImV1V5fzZWARjGMr0xOrnXYIJn1inum5JGRasaahjtat8j9gJ00z7sBXc7z");

    const { t } = useTranslation();

    const appearance = {
        theme: 'flat',
        labels: 'floating',
        variables: {
            fontSize: '10px',
            fontFamily: ' "Gill Sans", sans-serif',
            fontLineHeight: '1.5',
            borderRadius: '0.375rem',
            colorBackground: '#F6F8FA',
            accessibleColorOnColorPrimary: '#262626',

            fontSizeBase: '10px'
        },
        rules: {
            '.Block': {
                backgroundColor: 'var(--colorBackground)',
                boxShadow: 'none',
                padding: '12px'
            },
            '.Input': {
                padding: '2px',
                paddingLeft: '12px',
                fontSize: '10px',
                color: '#fff',
                backgroundColor: 'transparent',
                border: '1px solid #6a717f',
            },
            '.Input:focus': {
                paddingTop: '40px',
                borderColor: 'var(--colorPrimary)',
                boxShadow: '0 0 0 0.1px var(--colorPrimary)',
                outline: 'none'
            },
            '.Input:disabled, .Input--invalid:disabled': {
                color: 'lightgray'
            },
            '.Tab': {
                padding: '10px 12px 8px 12px',
                border: 'none'
            },
            '.Tab:hover': {
                border: 'none',
                boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 7px rgba(18, 42, 66, 0.04)'
            },
            '.Tab--selected, .Tab--selected:focus, .Tab--selected:hover': {
                border: 'none',
                backgroundColor: '#fff',
                boxShadow: '0 0 0 1.5px var(--colorPrimaryText), 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 7px rgba(18, 42, 66, 0.04)'
            },
            '.Label': {
                fontWeight: '500',
                color: '#9ca3af',
                padding: '2px',
            },
            //input placeholder


        }
    };

    useEffect(() => {
        fetch("https://api.whustle.com/create-payment-intent", {
            method: "POST",
            headers: {"Content-Type": "application/json"},
        })
            .then(res => res.json())
            .then(data => setClientSecret(data.clientSecret));
    }, []);

    return (clientSecret && (
        <Elements stripe={stripePromise} options={{clientSecret, appearance}}>
            <BrowserRouter>
                <div className={"flex bg-[#141414]"}>
                    <div className="fixed top-4 right-4 flex flex-col-reverse items-end space-y-4 pb-4 z-50">
                        <ToastContainer/>
                    </div>
                    <div className={"w-full"}>
                        <Routes>
                            <Route path="/" element={<Home locale={userLocale}/>}/>
                            <Route path="/login" element={<Login/>}/>
                            <Route path="/paid/:id" element={<Paid/>}/>
                            <Route path="/register/:email" element={<Register/>}/>
                            <Route path="/faktury" element={<Faktury/>}/>
                            <Route path="/resell" element={<ResellShopy/>}/>
                            <Route path="/funkce" element={<Funkce/>}/>
                            <Route path="/user" element={<User/>}/>
                            <Route path="/datart" element={<Datart/>}/>
                            <Route path="/refund" element={<RefundPolicy/>}/>
                            <Route path="/tos" element={<TermsOfService/>}/>
                            <Route path="/alzask" element={<AlzaSK/>}/>
                            <Route path="/rey" element={<Rey/>}/>
                            <Route path="/subscribe" element={<Payment/>}/>
                            <Route path="/sell" element={<StockXSell/>}/>
                            <Route path="/buy" element={<StockXBuy/>}/>
                            <Route path="/ucet" element={<Ucet/>}/>
                            <Route path="/test" element={<Checkout/>}/>
                        </Routes>
                    </div>
                </div>
            </BrowserRouter>
        </Elements>))
}

export default App;